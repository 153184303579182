header {
    width: var(--content-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 36px;

    h2 {
        font-size: 24px;
        font-weight: 800;
        text-transform: capitalize;

        @media screen and (max-width: 1024px) {
            font-size: 36px;
        }
    }
}

.button-group {
    button {
        &:not(:last-child) {
            margin-right: 8px;
        }
    }

    @media screen and (max-width: 1024px) {
        margin-top: 24px;
    }
}

.header {
    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
    }
}