@import './reset.scss';
@import './font.scss';

.app {
    background-color: var(--background-color);
    color: var(--text-color);
    transition: ease-in .7s;
    height: 100%;
    display: table-cell;
}

html,
body {
    --content-width: 80%;
    font-family: "Nunito Sans", sans-serif;
    overflow-x: hidden;

    @media screen and (max-width: 1024px) {
        --content-width: 90%;
    }

    .light {
        --background-color: #fbfbfb;
        --text-color: #070D0D;
        --bannerColor: var(--text-color);
        --note1: #FFEBA2;
        --note2: #FFEBA2;
        --note3: #FFEBA2;
        --note4: #FFEBA2;
        --note5: #FFEBA2;
        --note6: #FFEBA2;
        --noteGradient: 5px 5px 10px -2px rgba(33, 33, 33, .3);
    }

    .dark {
        --background-color: #020218;
        --text-color: white;
        --bannerColor: #08083a;
        --note1: #5CCD78;
        --note2: #54B8D6;
        --note3: #F67C39;
        --note4: #B35CD6;
        --note5: #E9689A;
        --note6: #FFEBA2;
        --noteGradient: /* shadow ring 👇 */
        0 0 0 1px hsla(0, 0%, 0%, 0.05),
        /* multiple soft shadows 👇 */
        0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
        0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
        0 3.5px 6px hsla(0, 0%, 0%, 0.09);
    }
}

.toggle {
    z-index: 30000;
    #toggle_checkbox {
        display: none;
      }
      
      label {
        position: fixed;
        bottom: 10px;
        left: 10px;
        width: 50px;
        height: 25px;
        cursor: pointer;
      }
      
      label:before {
        top: 5px;
        left: 8px;
        width: 15px;
        height: 15px;
        border: 3px solid var(--text-color);
      }
      
      label:after {
        top: 0;
        right: 0;
        width: 50px;
        height: 25px;
        border: 3px solid var(--text-color);
      }
      
      label:before,
      label:after {
        content: "";
        position: absolute;
        border-radius: 50px;
        box-sizing: border-box;
        transition: 0.5s ease top, 0.5s ease left, 0.5s ease right, 0.5s ease width,
          0.5s ease height, 0.5s ease border-color;
      }
      
      #toggle_checkbox:checked + label:before {
        top: 0;
        left: 0;
        width: 50px;
        height: 25px;
        border-color: var(--text-color);
      }
      
      #toggle_checkbox:checked + label:after {
        top: 5px;
        right: 8px;
        width: 15px;
        height: 15px;
        border-color: var(--text-color);
      }
      
}