.wrapper {
    width: var(--content-width);
    margin: 0 auto;
}

.vert-offset-24 {
    margin-bottom: 24px;
}

.section-home {
    p {
        @media screen and (max-width: 1024px) {
            font-size: 24px;
            line-height: 32px;
        }
    }
}

.note {
    float: left;
    display: block;
    position: relative;
    padding: 1em;
    width: 200px;
    height: 200px;
    background: linear-gradient(to-bottom, rgba(0, 0, 0, .05), rgba(0, 0, 0, .25));
    box-shadow: var(--noteGradient);
    z-index: 1;
    margin-bottom: 24px;
    margin-right: 24px;
    flex: 1 0 30%;

    &-1 {
        background-color: var(--note1);
    }

    &-2 {
        background-color: var(--note2);
    }

    &-3 {
        background-color: var(--note3);
    }

    &-4 {
        background-color: var(--note4);
    }

    &-5 {
        background-color: var(--note5);
    }

    &-6 {
        background-color: var(--note6);
    }

    &-text {
        color: black;
    }

    &s-wrapper {
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 624px) {
            flex-direction: column;
            align-items: center;
        }
    }
}