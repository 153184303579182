.headshot {
    border-radius: 2px;
    max-width: 350px;
    max-height: 350px;

    @media screen and (max-width: 1024px) {
        margin: auto;
        align-self: center;
        margin-bottom: 36px;
        width: 100%;
    }
}

.hero {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        text-align: center;
    }

    &__stuff {
        margin-left: 24px;

        @media screen and (max-width: 1024px) {
            margin-left: unset;
        }
    
    }

    &__para {
        font-weight: 200;
        font-size: 1.5vw;
        line-height: 28px;

        @media screen and (max-width: 1024px) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &__text {
        font-weight: 900;
        font-size: 5vw;
        margin-bottom: 24px;

        @media screen and (max-width: 1024px) {
            font-size: 36px;
        }
    }
    
}

.underline-animation {
    text-decoration: none; 
    position: relative;
    white-space: nowrap;
    
    svg {
      position: absolute;
      top: 80%;
      left: 0;
      width: 100%;
      height: auto;
      z-index: -1;
      transition: ease-in .7s;
    }
    
    .stroke {
      stroke: var(--note1);
      stroke-width: .15em;
      stroke-dasharray: 822;
      stroke-dashoffset: 822;
      animation: underlineAnimation 1.8s 1s linear forwards;
    }
  }
  
  @keyframes underlineAnimation {
    from {
      stroke-dashoffset: 822;
    }
    to {
      stroke-dashoffset: 0;
    }
  }