@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Wix+Madefor+Display&display=swap');

h3 {
    font-weight: 800;
    font-size: 3.5vw;
    margin-bottom: 24px;

    @media screen and (max-width: 1024px) {
        font-size: 36px;
    }
}

p {
    font-weight: 200;
    font-size: 2vw;
    line-height: 36px;
}