.button {
    &__outline {
        background-color: transparent;
        color: var(--text-color);
        border: 2px solid var(--text-color);
        border-radius: 2px;
        font-weight: 600;
        padding: 6px 12px;
        transition: all .6s;

        &:hover {
            background-color: var(--text-color);
            border: 2px solid var(--text-color);
            color: var(--background-color);
            cursor: pointer;
        }
    }      
}

.wiggle {
    animation: bounce 0.5s linear 0s infinite alternate;
    position: relative;
}

@keyframes bounce {
    0% {
      bottom: 0;
    }
    100% {
      bottom: 5px;
    }
  }