.resume-banner {
    margin-top: 36px;
    background-color: var(--bannerColor);
    color: white;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    transition: ease-in .7s;
}